import React from "react";
import { MdOutlineArrowOutward } from "react-icons/md";

const Footer = () => {
  return (
    <div className="bg-black text-white p-8">
      <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
        <div className="flex flex-col md:flex-row justify-between">
          <div className="flex flex-col gap-4">
            <h2 className="text-2xl md:text-[56px]  mb-4">Stay in Touch</h2>
            <p className="text-sm md:text-[20px] m">Contact</p>
            <p className="text-sm  md:text-[20px]">
              Mahaveer Ranches, Hosa Road,
              <br />
              Bengaluru, Karnataka 560100
            </p>
          </div>
          <div className="mt-8 md:mt-0">
            <div className="flex flex-col h-full justify-between ">
              <div>
                <a
                  href="https://play.google.com/store/apps/details?id=com.av.findhope&hl=en_US&pli=1"
                  className="flex items-center w-full md:text-[38px] mb-4 md:mb-0 text-white hover:underline">
                  <MdOutlineArrowOutward className="mr-3" />
                  Android App
                </a>
              </div>
              <div>
                <a
                  href="https://apps.apple.com/in/app/sociverse-the-event-hub/id6471382597"
                  className="flex items-center  w-full md:text-[38px] mb-4 md:mb-0 text-white hover:underline">
                  <MdOutlineArrowOutward className="mr-3" />
                  iOS App
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
