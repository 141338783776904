import React, { useState } from 'react'

import { IoClose } from "react-icons/io5";
import { FaCheckCircle } from "react-icons/fa";
import { resetPassword } from '../redux/slice/ResetPassSlice';
import check from "../assets/check.png"
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';


const AlertDialogBox = ({ title, heading, id, pass, Login }) => {
  const [isvisible, setVisible] = useState(false);
  const dispatch = useDispatch();
  const handleResetPass = () => {
    if (id && pass) {
      dispatch(resetPassword({ id, pass })).then((response) => {
        setVisible(true);
      });
    }
  };
  return (
    <>
      <button
        onClick={handleResetPass}
        className="flex items-center justify-center font-bold text-sm md:w-36 md:text-lg py-2 px-4 hover:bg-sky-400 hover:rounded-xl hover:text-white">
        {title}
      </button>
      {isvisible && (
        <div
          id="modelConfirm"
          className="fixed  z-50 inset-0 bg-gray-900 bg-opacity-60 overflow-y-auto h-full w-full px-4 ">
          <div className="relative top-40 mx-auto shadow-xl rounded-md bg-white max-w-md">
            <div className="flex justify-end p-2">
              <button
                onClick={() => setVisible(false)}
                type="button"
                className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center">
                <IoClose />
              </button>
            </div>
            <div className="p-6 pt-0 text-center">
              <div className="w-full flex justify-center items-center">
                <img className="w-28 h-28" src={check} alt="" />
              </div>
              <h3 className="text-xl font-normal text-gray-500 mt-2 mb-6">
                {heading}
              </h3>
              <Link
                to="/Login"
                className="text-white bg-[#18A2F3] hover:bg-sky-800 focus:ring-4 focus:ring-sky-300 font-medium rounded-lg text-base inline-flex items-center px-3 py-2.5 text-center mr-2">
                Login
              </Link>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AlertDialogBox