import { React, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchData } from "../redux/slice/EventDataSlice";
import DOMPurify from "dompurify";
import moment from "moment";
import { FaLocationDot } from "react-icons/fa6";
import { Link } from "react-router-dom";
import noImg from "../assets/noImg.jfif";
var flag = true;
export const EventCard = ({ event }) => {
  const [startdate, setStardate] = useState(null);
  useEffect(() => {
    const date = new Date(event.startTime);
    setStardate(moment(date).format("DD MMM YYYY, hh:mm A"));
  }, [event]);
   const sanitizeHtml = (html) => {
     return { __html: DOMPurify.sanitize(html) };
   };

  return (
    <>
      <div className="flex flex-col bg-white border shadow-md rounded-xl ">
        {!event.attachmentLink ? (
  <img
    className="w-full h-52 rounded-t-xl"
    src={noImg}
    alt="Image Description"
  />
) : (
  <Link to={`/Event/${event.uuid}`}>
  <div className="w-full">
    <img
      className="w-[23rem] h-52 object-cover rounded-t-xl"
      src={event.attachmentLink}
      alt="Image Description"
    />
  </div>
  </Link>
)}
        <div className="p-4 md:p-5">
          <h3 className="text-lg line-clamp-1 font-bold text-gray-800">
            {event.name}
          </h3>
          <div className="flex mt-1 justify-between items-center">
          <div className="flex items-center gap-1 w-3/5">
            <FaLocationDot className="text-green-500" />
            <p className="text-sm md:text-md truncate text-gray-400">
              {event.address}
            </p>
          </div>
      <div className="flex items-center justify-center w-1/5"></div>
      <div className="flex items-center justify-center w-2/5">
        <div className="flex text-blue-600 bg-sky-100 p-1 rounded-lg text-xs justify-center w-full">
          <p>{event.eventMode}</p>
        </div>
      </div>

      
    </div>

          <div className="flex mt-3 justify-between">
            <div className="max-mem">
              <p className=" text-xs font-bold md:text-base">Start Date</p>
              <p className="text-gray-400">{startdate}</p>
            </div>
          </div>
          <p className="font-bold  mt-3 ">Description</p>
          <div className="w-full mt-3 h-[3em] text-xs text-gray-500 line-clamp-3">
            {typeof event.summary === "string" &&
            event.summary.includes("<") ? (
              <p
                className="leading-[1em]"
                dangerouslySetInnerHTML={sanitizeHtml(event.summary)}
              />
            ) : (
              <p className="leading-[1em]">{event.summary}</p>
            )}
          </div>

          <Link
            className="mt-3 py-2 px-3 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-[#55D3FC] text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none"
            to={`/Event/${event.uuid}`}>
            See More
          </Link>
        </div>
      </div>
    </>
  );
};
