import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchEventDetail } from "../redux/slice/SingleEventSlice";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { Link } from "react-router-dom";
import { CiEdit } from "react-icons/ci";
import { FaLocationDot } from "react-icons/fa6";
import Loader from "../components/Loader";
import { FaUserCircle, FaRegUser } from "react-icons/fa";
import { MdOutlineJoinLeft } from "react-icons/md";
import { IoIosArrowBack } from "react-icons/io";
import { AiOutlineInfoCircle } from "react-icons/ai";
import noImg from "../assets/noImg.jfif";
import DialogBox from "../Features/DialogBox";
import { fetchPendingRequests } from "../redux/slice/EventPendingRequestSlice";
import CreateEvent from "../components/CreateEvent";
import RequestListing from "../Features/RequestListing";
import DOMPurify from "dompurify";

const EventDetail = () => {
  const [isVisible, setVisible] = useState(false);
  const dispatch = useDispatch();
  const [profile, setProfile] = useState({});
  const [isLogin, setLogin] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  const token = localStorage.getItem("accessToken");
  const { EventDetail, loading, error } = useSelector(
    (state) => state.EventDetail
  );
  useEffect(() => {
    dispatch(fetchEventDetail({ id, token }));
  }, [, id, token]);

  useEffect(() => {
    const profileData = localStorage.getItem("profileData");
    if (profileData) {
      setProfile(JSON.parse(profileData));
    }
    if (localStorage.getItem("accessToken")) {
      console.log('Token Found');
      setLogin(true);
    } else {
      console.log('Token Not Found');
      setLogin(false);
    }
  }, []);

  // useEffect(() => {
  //   if (localStorage.getItem("accessToken")) {
  //     console.log('Token Found');
  //     setLogin(true);
  //   } else {
  //     console.log('Token Not Found');
  //     setLogin(false);
  //   }
  // });

  const handleMaps = () => {
    const url = `https://www.google.com/maps?q=${EventDetail.location}`;
    window.open(url, "_blank");
  };

  const { pendingRequests, status } = useSelector(
    (state) => state.PendingRequest
  );

  const [startdate, setStartdate] = useState(null);
  const [enddate, setEndate] = useState(null);

  useEffect(() => {
    if (EventDetail) {
      const date1 = new Date(EventDetail.startTime);
      const formattedDate1 = moment(date1).format("DD MMM YYYY, hh:mm A");
      const date2 = new Date(EventDetail.endTime);
      const formattedDate2 = moment(date2).format("DD MMM YYYY, hh:mm A");
      setStartdate(formattedDate1);
      setEndate(formattedDate2);
    }
  }, [EventDetail]);

  const Editevent = () => {
    setVisible(true);
  };

  // Calling Pending Request Api
  useEffect(() => {
    dispatch(fetchPendingRequests({ id, token }));
  }, []);

  const sanitizeHtml = (html) => {
    return { __html: DOMPurify.sanitize(html) };
  };
  const handleBack =()=>{
    setVisible(false);
  }

  const handelJoinEvent=()=>{
    const token =localStorage.getItem("accessToken");
     if (!token) {
      console.log('Token not found');
      alert("Please Login to Join the event.");
      return;
     }
 }
 

  if (loading) {
    return (
      <div className="h-screen flex justify-center items-center">
        <Loader />
      </div>
    );
  }

  if (!EventDetail) {
    return (
      <div className="h-screen flex justify-center items-center">
        Event details are loading...
      </div>
    );
  }
  return (
    <>
      {isVisible && (
        <button
          onClick={handleBack}
          className="back-icon  tex-sm text-white md:text-xl flex justify-center items-center md:p-2 md:text-white bg-black hover:bg-sky-400 hover: absolute left-0">
          <IoIosArrowBack /> Back
        </button>
      )}
      {isVisible ? (
        <CreateEvent Details={EventDetail} />
      ) : (
        <>
          <section className="flex min-h-screen  justify-center">
            <div className="flex flex-col h-full shadow container w-[100%] p-6">
              <div className="banner-img relative w-full flex items-center justify-center">
                <img
                  className="w-full md:w-[38rem] h-58"
                  src={EventDetail.attachmentLink || noImg}
                  alt=""
                />
                {EventDetail.host.id === profile.id ? (
                  <div className="edit-icon">
                    <button
                      onClick={Editevent}
                      className="absolute text-sm text-white bg-black md p-1 md:p-2  top-0 right-0 border-2 flex gap-1 justify-center items-center md:hover:bg-sky-400 md:hover:text-white">
                      <CiEdit /> Edit Event
                    </button>
                  </div>
                ) : null}
              </div>
              <h1 className="md:text-4xl font-bold mt-3">{EventDetail.name}</h1>
              <div className="flex mt-3 justify-between items-center">
                <div
                  onClick={handleMaps}
                  className="flex cursor-pointer items-center gap-1">
                  <FaLocationDot className="text-green-500" />
                  <p className="text-md md:text-lg line-clamp-1 text-gray-400">
                    {EventDetail.address}
                  </p>
                </div>
                <div className="flex items-center text-sm gap-5 font-medium">
                  <a href={EventDetail.link}>
                    <p className="text-lg text-blue-300">{EventDetail.link}</p>
                  </a>
                  <div className="flex gap-1 text-blue-600 bg-sky-100 p-1 rounded-lg text-lg justify-center w-20">
                    <p>{EventDetail.eventMode}</p>
                  </div>
                </div>
              </div>
              <div className="mt-3 w-full cursor-pointer md:w-1/2 grid grid-cols-2     md:grid-cols-3">
                <Link to={token ? `/host/${EventDetail.host.id}` : ""}>
                  <button
                    className={`flex ${!token && " blur-sm"}  items-center`}>
                    <img
                      className="w-10 object-cover h-10 rounded-full"
                      src={
                        EventDetail.host.profilePicture ? (
                          EventDetail.host.profilePicture
                        ) : (
                          <FaUserCircle />
                        )
                      }
                      alt="Rounded avatar"
                    />
                    <span className="p-1 ml-1 text-small text-gray-400">
                      {EventDetail.host.name}
                    </span>
                  </button>
                </Link>
                <div className="max-mem flex items-center">
                  <FaRegUser className="text-xl" />
                  <span className="p-1 ml-1 text-small text-gray-400">
                    Max Member {EventDetail.maxMember}
                  </span>
                </div>
                <div className="max-mem flex items-center">
                  <div className="w-14 flex items-center justify-center text-green-500 bg-green-100 p-1 rounded-xl">
                    <MdOutlineJoinLeft className="text-lg md:text-3xl" />
                  </div>
                  <span className="mt-2 md:mt-0 p-1 ml-1 text-small text-gray-400">
                    Spot Left {EventDetail.spotsLeft}
                  </span>
                </div>
              </div>
              <div className="mt-3 text-xl text-black ">
                {typeof EventDetail.summary === "string" &&
                EventDetail.summary.includes("<") ? (
                  <p
                    className="leading-[1em]"
                    dangerouslySetInnerHTML={sanitizeHtml(EventDetail.summary)}
                  />
                ) : (
                  <p className="leading-[1em]">
                    <span className="font-bold">Description : </span>{" "}
                    {EventDetail.summary}
                  </p>
                )}
              </div>
              <div className="mt-3 text-sm">
                <p>
                  <strong>Start Date :</strong> {startdate}
                </p>
              </div>
              <div className="mt-3 text-sm">
                <p>
                  <strong>End Date :</strong> {enddate}
                </p>
              </div>
              <div className="mt-3 text-xl">
              <p className="font-bold w-28 md:col-span-1">Interest :</p>
                <div className="w-full grid grid-cols-2 md:grid-cols-3 gap-2 lg:grid-cols-10">
                  
                  {EventDetail.tags.map((Tags, index) => (
                    <div
                      key={index}
                      className="flex justify-center items-center p-1 rounded-xl text-white bg-sky-400">
                      <p className="text-xs md:text-sm">{Tags}</p>
                    </div>
                  ))}
                </div>
              </div>
              {pendingRequests.length > 0 ? (
                <div className="mt-3 text-xl">
                  <p>
                    <strong>Attendees ({EventDetail.attendees.length})</strong>
                  </p>
                </div>
              ) : (
                <div className="mt-3 text-xl font-bold">
                  <p>Going ({EventDetail.attendees.length})</p>
                  
                    <RequestListing
                      heading={"Attendies"}
                      rejectbtn={"Remove"}
                      data={EventDetail.attendees}
                      profileID={profile.id}
                      EventDetailID={EventDetail.host.id}
                    />
                
                </div>
              )}
              {EventDetail.host.id === profile.id ? (
                <div className="mt-3 text-xl font-bold">
                  <p>Pending request ({pendingRequests.length})</p>
                  <RequestListing
                    heading={"Attendies"}
                    rejectbtn={"Reject"}
                    data={pendingRequests}
                  />
                </div>
              ) : null}
              {profile.id !== EventDetail.host.id ? (
                <div className="mt-3 text-xl font-bold">
                  <DialogBox id={id} />
                </div>
              ) : null}
              {EventDetail.host.id === profile.id ? (
                <div className="mt-6 md:mt-3 flex items-center justify-center">
                  <a href="">
                    <button className="group relative h-12 w-48 overflow-hidden rounded-lg bg-sky-50 text-2xl shadow-2xl">
                      <div className="absolute inset-0 w-3 bg-orange-600 transition-all duration-[400ms] ease-out group-hover:w-full" />
                      <span className="relative font-bold text-gray-500 group-hover:text-white">
                        Suspend event
                      </span>
                    </button>
                  </a>
                </div>
              ) : EventDetail.userJoined ? (
                <div className="mt-6 md:mt-3 flex items-center justify-center">
                  <a href="">
                    <button className="group relative h-12 w-48 overflow-hidden rounded-lg bg-sky-50 text-2xl shadow-2xl">
                      <div className="absolute inset-0 w-3 bg-orange-600 transition-all duration-[400ms] ease-out group-hover:w-full" />
                      <span className="relative font-bold text-gray-500 group-hover:text-white">
                        Cancel Invite
                      </span>
                    </button>
                  </a>
                </div>
              ) : (
                // <div>{token =localStorage.getItem("accessToken") ?(
                
                <div className="mt-6 md:mt-3 flex items-center justify-center">
                  <a href={EventDetail.bookingURL || ""}>
                    <button onClick={handelJoinEvent} className="group relative h-12 w-48 overflow-hidden rounded-lg bg-sky-50 text-2xl shadow-2xl">
                      <div className="absolute inset-0 w-3 bg-green-400 transition-all duration-[400ms] ease-out group-hover:w-full" />
                      <span className="relative font-bold text-gray-500 group-hover:text-white">
                        Join event
                      </span>
                    </button>
                  </a>
                </div>
              // ):(<div>

              //   </div>)}
              //   </div>
              )}
            </div>
          </section>
        </>
      )}
    </>
  );
};

export default EventDetail;
