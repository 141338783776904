import { React, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { fetchJoinedEvents } from "../redux/slice/JoinedEventSlice";
import { EventCard } from "./EventCard";
import Loader from "./Loader";
import JoinedEvent from "./JoinedEvent";
import OrganisedEvent from "./OrganisedEvent";

const HomePage = () => {
  const token =localStorage.getItem("accessToken");
  return (
    <>
      {token ? (
        <div className="min-h-screen flex justify-center items-center p-3">
          <div className="container  p-2 mt-3">
            <h1 className="text-2xl ml-2 font-medium">Joined Events</h1>
            <JoinedEvent />
            <OrganisedEvent />
          </div>
        </div>
      ) : (
        <div className="h-screen flex justify-center items-center">
          <div className="flex items-center flex-col gap-6">
            <h1 className="font-bold text-3xl">
              No Events Found | Kindly Login
            </h1>
            <Link to="/Login">
              <button
                type="button"
                className="focus:outline-none text-white bg-[#60A5FA] hover:bg-[#60A5FA] focus:ring-4  font-medium rounded-lg text-sm px-5 py-2.5 mb-2 ">
                Login
              </button>
            </Link>
          </div>
        </div>
      )}
    </>
  );
};

export default HomePage;
