// src/features/user/userSlice.js

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const initialState = {
    host: {},
    status: 'idle',
    error: null,
};

export const fetchHost = createAsyncThunk(
    'user/fetchUser',
    async ({id,token}) => {
        const response = await axios.get(`https://api.sociverse.in/user/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        return response.data.result;
    }
);

const HostSlice = createSlice({
    name: 'host',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchHost.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchHost.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.host = action.payload;

            })
            .addCase(fetchHost.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
    },
});

export default HostSlice.reducer;
