import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchTags } from "../redux/resourcesSlice/Tags";
import Datetime from "react-datetime";
import moment from "moment";
import { IoLocationOutline } from "react-icons/io5";
import { BsCheckCircleFill } from "react-icons/bs";
import { IoCloseCircleSharp } from "react-icons/io5";
import MapComponent from "../Features/MapComponent"; // Import the MapComponent
import { postEvent } from "../redux/slice/CreateEventSlice";
import { updateEvent } from "../redux/slice/UpdateEventSlice";
import { fetchDetails } from "../redux/slice/SingleEventSlice";
import { Link, useNavigate, useParams } from "react-router-dom";
import { divide } from "lodash";
import DateValidation from "../Features/DateValidation";
import AlertDialogBox from "../Features/AlertDialogBox";

var flag = false;

const EventForm = ({ Details }) => {
  const id=useParams()
  const [isVisible ,setVisible]=useState(false)
  const [startTime, setStartTime] = useState("");
  const[error , setError]=useState("")
  const [endTime, setEndTime] = useState("");
  const [isLogin , setLogin]= useState(false)
  const navigate = useNavigate();
  const { tags } = useSelector((state) => state.Tag);
  const token = localStorage.getItem("accessToken");
  const dispatch = useDispatch();
  const [isMapVisible, setMapVisible] = useState(false); // State to toggle map visibility
  const accessToken = localStorage.getItem("accessToken");
  const { city, postalCode, state } = useSelector(
    (state) => state.address.addressDetails
  );
 
  const [eventData, setEventData] = useState({

    address: "",
    autoAccept:false,
    city: "",
    endTime: "",
    maxMember: "",
    name: "",
    startTime: "",
    state: "",
    summary: "",
    zipcode: "",
    location: "",
    tags: [],
    eventMode: [],
    cost: 0,
  });
  const formatDate = (dateString) => {
    const date = new Date(dateString);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based, so add 1
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");

    return `${year}-${month}-${day}T${hours}:${minutes}`;
  };


  useEffect(() => {
    if (Details) {
      setEventData(
        {
          ...eventData,
          ...Details,
          startTime: formatDate(Details.startTime),
          endTime: formatDate(Details.endTime),
        },
      );
    }
  }, [startTime , endTime]);
  
  useEffect(() => {
    if (Details) {
      setEndTime(eventData.endTime);
      setStartTime(eventData.startTime);
      flag = true;
    }
  }, [eventData.endTime, eventData.startTime]);
 
  const today = new Date().toISOString().slice(0, 16);
  const handleEventmode = (e) => {
    const { name, value, type, checked } = e.target;
    setEventData((prevEventData) => {
      let newValue;
      if (checked) {
        if (prevEventData[name] === "") {
          newValue = value;
        } else if (
          (prevEventData[name] === "OFFLINE" && value === "ONLINE") ||
          (prevEventData[name] === "ONLINE" && value === "OFFLINE")
        ) {
          newValue = "HYBRID";
        } else {
          newValue = value;
        }
      } else {
        if (prevEventData[name] === "HYBRID") {
          newValue = value === "OFFLINE" ? "ONLINE" : "OFFLINE";
        } else {
          newValue = "";
        }
      }

      return {
        ...prevEventData,
        [name]: newValue,
      };
    });
  };
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === "checkbox") {
      setEventData((prevEventData) => ({
        ...prevEventData,
        [name]: checked
          ? [...prevEventData[name], value]
          : prevEventData[name].filter((item) => item !== value),
      }));
    } else {
      setEventData((prevEventData) => ({
        ...prevEventData,
        [name]: value,
      }));
    }
  };

  const handleLocationSelect = (location) => {
    setEventData((prevEventData) => ({
      ...prevEventData,
      location: `${location.lat},${location.lng}`,
    }));
  };
 
  useEffect(() => {
    dispatch(fetchTags());
  }, [dispatch]);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const handleButtonClick = (event) => {
    event.preventDefault();
    setIsDropdownVisible(!isDropdownVisible);
  };
  const convertToUTC = (dateString) => {
    const date = new Date(dateString);
    if (isNaN(date)) {
      return "Invalid Date";
    }
    return date.toISOString();
  };

  const handleComplete = () => {
    if(eventData.eventMode.length<3){
      setError("Select at least 3 tags")
    }
    if (
      eventData.name &&
      eventData.maxMember &&
      eventData.summary
    ) {
      eventData.city = city;
      eventData.state = state;
      eventData.zipcode = postalCode;
      eventData.startTime = convertToUTC(startTime);
      eventData.endTime = convertToUTC(endTime);
      dispatch(postEvent({ eventData, accessToken })).then((response) => {
         setVisible(true);
      });
    } else {
      alert("Please fill in all required fields");
    }
  };
  const handlUpdateEvent=()=>{
     const uuid = Details.uuid;
      if (
        eventData.name &&
        eventData.maxMember &&
        eventData.address &&
        eventData.summary
      ) {
        eventData.city = city;
        eventData.state = state;
        eventData.zipcode = postalCode;
        eventData.startTime = convertToUTC(startTime);
        eventData.endTime = convertToUTC(endTime);
       dispatch(updateEvent({ eventData, uuid, token })).then((response) => {
         navigate(0);
       });
      } else {
        alert("Please fill in all required fields");
      }
       
  }

  return (
    <>
      {token ? (
        <div className="min-h-screen flex justify-center items-center p-6">
          <div className="p-8 rounded-lg shadow-2xl w-full max-w-5xl">
            <h2 className="text-2xl font-bold mb-6">
              {Details ? "Update Event" : "Create Event"}
            </h2>
            <div>
              {/* Other form fields */}
              <div className="mb-4 md:flex justify-between gap-4">
                <div className="w-full">
                  <label
                    className="block text-sm font-bold mb-2"
                    htmlFor="title">
                    Name
                  </label>
                  <input
                    type="text"
                    id="title"
                    required
                    placeholder="Enter your title event"
                    name="name"
                    value={eventData.name}
                    onChange={handleChange}
                    className="w-full p-2 rounded-md border border-gray-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
                  />
                </div>
              </div>
              {/* Summary  */}
              <div className="mb-4">
                <label
                  className="block text-sm font-bold mb-2"
                  htmlFor="summary">
                  Description
                </label>
                <textarea
                  id="summary"
                  required
                  placeholder="Write a description here"
                  name="summary"
                  value={eventData.summary}
                  onChange={handleChange}
                  className="w-full p-2 rounded-md border border-gray-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
                  rows="4"></textarea>
              </div>
              {/* Max Member  */}
              <div className="max-w-52">
                <label
                  className="block text-sm font-bold mb-2"
                  htmlFor="maxMember">
                  Max Members
                </label>
                <input
                  name="maxMember"
                  required
                  type="text"
                  id="maxMember"
                  placeholder={
                    Details ? Details.maxMember : "Please Enter Max Members"
                  }
                  value={eventData.maxMember}
                  onChange={handleChange}
                  className="w-full p-2 rounded-md border border-gray-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
              </div>
              <div className="mb-4 mt-4">
                <div className="md:flex gap-4 justify-between">
                  <div className="w-full">
                    <label
                      className="block text-sm font-bold mb-2"
                      htmlFor="startTime">
                      Start Date
                    </label>
                    <div className="flex">
                      <input
                        required
                        type="datetime-local"
                        id="startTime"
                        value={startTime}
                        min={today}
                        onChange={(e) => setStartTime(e.target.value)}
                        className="mx-2 p-2 rounded-md border border-gray-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
                      />
                    </div>
                  </div>
                  <div className="w-full">
                    <label
                      className="block text-sm font-bold mb-2"
                      htmlFor="endTime">
                      End Date
                    </label>
                    <div className="flex">
                      <input
                        required
                        type="datetime-local"
                        id="endTime"
                        value={endTime}
                        min={today}
                        onChange={(e) => setEndTime(e.target.value)}
                        className="mx-2 p-2 rounded-md border border-gray-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
                      />
                    </div>
                    <DateValidation startTime={startTime} endTime={endTime} />
                  </div>
                </div>
              </div>

              <div className="mb-4">
                <label
                  className="block text-sm font-bold mb-2"
                  htmlFor="eventMode">
                  Event Mode
                </label>
                <div className="flex gap-4 items-center">
                  <div className="flex">
                    <input
                      required
                      type="checkbox"
                      id="OFFLINE"
                      name="eventMode"
                      value="OFFLINE"
                      checked={
                        eventData.eventMode === "OFFLINE" ||
                        eventData.eventMode === "HYBRID"
                      }
                      onChange={handleEventmode}
                      className="mr-2"
                    />
                    <label htmlFor="OFFLINE" className="text-sm">
                      OFFLINE
                    </label>
                  </div>
                  <div className="flex">
                    <input
                      required
                      type="checkbox"
                      id="ONLINE"
                      name="eventMode"
                      value="ONLINE"
                      checked={
                        eventData.eventMode === "ONLINE" ||
                        eventData.eventMode === "HYBRID"
                      }
                      onChange={handleEventmode}
                      className="mr-2"
                    />
                    <label htmlFor="ONLINE" className="text-sm">
                      ONLINE
                    </label>
                  </div>
                </div>
              </div>

              {/* Location selection button */}
              {(eventData.eventMode === "OFFLINE" ||
                eventData.eventMode === "HYBRID") && (
                <div className="mb-4">
                  <button
                    type="button"
                    onClick={() => setMapVisible(!isMapVisible)}
                    className="bg-sky-300 p-2 w-lg flex justify-between items-center text-white rounded-md">
                    <IoLocationOutline /> Tap to Select Location
                  </button>
                </div>
              )}

              {isMapVisible && (
                <div className="mb-4">
                  <MapComponent onLocationSelect={handleLocationSelect} />
                </div>
              )}

              {/* Other form fields */}
              {eventData.eventMode !== "ONLINE" && (
                <div className="mb-4">
                  <label
                    className="block text-sm font-bold mb-2"
                    htmlFor="address">
                    Event Address
                  </label>
                  <div className="flex space-x-2">
                    <input
                      required
                      name="address"
                      type="text"
                      id="address"
                      value={eventData.address}
                      onChange={handleChange}
                      placeholder="Enter address of the event"
                      className="w-full p-2 rounded-md border border-gray-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
                    />
                  </div>
                </div>
              )}
              {(eventData.eventMode === "ONLINE" ||
                eventData.eventMode === "HYBRID") && (
                <div className="mb-4">
                  <label
                    className="block text-sm font-bold mb-2"
                    htmlFor="link">
                    Meeting Details
                  </label>
                  <div className="flex space-x-2">
                    <input
                      required
                      name="link"
                      type="text"
                      id="link"
                      placeholder="Enter meeting details"
                      value={eventData.link}
                      onChange={handleChange}
                      className="w-full p-2 rounded-md border border-gray-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
                    />
                  </div>
                </div>
              )}

              {/* Dropdown for tags */}
              <p className="text-red-700">{error}</p>
              <div className="mb-4">
                <div className="flex space-x-2">
                  <div>
                    <label
                      htmlFor="tags"
                      className="text-sm font-bold  text-gray-900 block mb-2">
                      Select Tags
                    </label>
                    <div className="relative w-full inline-block text-left">
                      <button
                        id="dropdownHoverButton"
                        onClick={handleButtonClick}
                        className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 w-full p-2.5 flex justify-between items-center">
                        Select Tags
                        <svg
                          className="w-2.5 h-2.5 ml-2.5"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 10 6">
                          <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="m1 1 4 4 4-4"
                          />
                        </svg>
                      </button>
                      {isDropdownVisible && (
                        <div
                          id="dropdownHover"
                          className="absolute z-10 max-h-60 overflow-y-auto shadow-lg bg-white divide-y divide-gray-100 rounded-lg w-60 dark:bg-white">
                          <ul
                            className="py-2 text-sm dark:text-gray-200"
                            aria-labelledby="dropdownHoverButton">
                            {tags.map((value, index) => (
                              <div
                                key={index}
                                className="flex items-center px-4 py-2">
                                <input
                                  name="tags"
                                  id={`checkbox-${index}`}
                                  type="checkbox"
                                  onChange={handleChange}
                                  value={value}
                                  checked={eventData.tags.includes(value)}
                                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                />
                                <label
                                  htmlFor={`checkbox-${index}`}
                                  className="ml-2 text-sm font-medium text-gray-900">
                                  {value}
                                </label>
                              </div>
                            ))}
                          </ul>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="mb-4 grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-2">
                {eventData.tags &&
                  eventData.tags.map((value, index) => (
                    <div className="flex items-center mb-4" key={index}>
                      <p className="ms-2 flex justify-center items-center w-full text-sm font-medium border-2 text-sky-400 border-sky-400 p-2">
                        {value}
                      </p>
                    </div>
                  ))}
              </div>

              <div className="mb-4">
                <div className="flex items-center space-x-2">
                  <input
                    type="checkbox"
                    name="autoAccept"
                    checked={eventData.autoAccept}
                    onChange={(e) => {
                      setEventData({
                        ...eventData,
                        autoAccept: e.target.checked,
                      });
                    }}
                    id="autoAccept"
                    className="mr-2"
                  />
                  <label htmlFor="seeGuestList" className="text-sm ">
                    Auto Accept <br />
                    <span className="text-gray-500">
                      Organiser will have the right to all to allow user after
                      checking their profile.
                    </span>{" "}
                  </label>
                </div>
              </div>

              {Details ? (
                <div className="flex justify-end">
                  <button
                    onClick={handlUpdateEvent}
                    className="bg-sky-300 text-white p-3 rounded-md">
                    Update Event
                  </button>
                </div>
              ) : (
                <div className="flex justify-end">
                  <button
                    onClick={handleComplete}
                    className="bg-sky-300 text-white p-3 rounded-md">
                    Confirm
                  </button>
                  {isVisible && (
                    <div className="fixed z-50 inset-0 bg-gray-900 bg-opacity-60 flex justify-center items-center">
                      <div className=" relative icon rounded-md shadow-xl bg-white p-2 flex gap-2 justify-center items-center">
                        <button onClick={()=>setVisible(false)} className="close-icon absolute top-0 right-0 text-lg"><IoCloseCircleSharp/></button>
                        <BsCheckCircleFill className="text-6xl text-sky-400" />
                        <p>Your Event Has Been Created Check Your Events</p>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className="h-screen flex justify-center items-center">
          <div className="flex items-center flex-col gap-6">
            {" "}
            <h1 className="font-bold text-3xl"> Kindly Login</h1>
            <Link to="/Login">
              <button
                type="button"
                className="focus:outline-none text-white bg-[#60A5FA] hover:bg-[#60A5FA] focus:ring-4  font-medium rounded-lg text-sm px-5 py-2.5 mb-2 ">
                Login
              </button>
            </Link>
          </div>
        </div>
      )}
    </>
  );
};

export default EventForm;
