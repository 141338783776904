import React, { useEffect, useState } from "react";
import { CiUser } from "react-icons/ci";
import { useSelector, useDispatch } from "react-redux";
import { fetchProfession } from "../../../redux/resourcesSlice/profession";
import { fetchInterests } from "../../../redux/resourcesSlice/Interest";
import { signup } from "../../../redux/slice/SIgnUpSlice/AuthDetailSlice";
import { Link } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";
import Alert from "../../../Features/Alert";
import { useNavigate } from 'react-router-dom';

const SignInfo = ({userContactDetails, contactType}) => {
  const { profession } = useSelector((state) => state.Profession);
  const { interests } = useSelector((state) => state.interests);
  const[error,setError]=useState("")
  const [Confirmpass, setconfirmPass] = useState("");
  const [alertMessage, setAlert] = useState(
    "You Are Successfully Singned Up"
  );
  const navigate = useNavigate();

  const { user } = useSelector((state) => state.authSlice);
  const [userData, setUserData] = useState({
    name: "",
    email: "",
    password: "",
    dob: "",
    gender: "",
    interests: [],
    profession: "",
    accountType: "EMAIL_OR_MOBILE"
  });
useEffect(() => {
  if (contactType === 'email') {
    setUserData({
      ...userData,
      email: userContactDetails,
    });
  } else if (contactType === 'phoneNumber') {
    setUserData({
      ...userData,
      countryCode: "+91",
      mobileNumber: userContactDetails,
    });
  }
}, [userContactDetails, contactType]);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchProfession());
    dispatch(fetchInterests());
  }, [dispatch]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === "checkbox") {
      setUserData((prevUserData) => ({
        ...prevUserData,
        interests: checked
          ? [...prevUserData.interests, value]
          : prevUserData.interests.filter((interest) => interest !== value),
      }));
    } else {
      setUserData((prevUserData) => ({
        ...prevUserData,
        [name]: value,
      }));
    }
  };

  const handleSave = () => {
    if(userData.interests.length<3){
setError("Select at least 3 interest")
    }
    else{
      dispatch(signup({ userData }));
      navigate('/Login'); 
    }
  };
  const [isDropdownVisible, setDropdownVisible] = useState(false);

  const handleMouseEnter = () => {
    setDropdownVisible(true);
  };

  const handleMouseLeave = () => {
    setDropdownVisible(false);
  };
  return (
    <>
      <div className="right  w-full h-[20%]  md:w-[60%]  ">
        {user &&
          (alertMessage ? (
            <div className="w-full ">
              <Alert alert={alertMessage} />
            </div>
          ) : (
            ""
          ))}
        <div className="flex items-start justify-between  p-3 border-b rounded-t">
          <h3 className="text-xl font-semibold">Personal Detail</h3>
        </div>
        <div className="p-6  space-y-6">
          <div>
            <div className="grid grid-cols-6 gap-6">
              <div className="col-span-6 sm:col-span-3">
                <label
                  htmlFor="name"
                  className="text-sm font-medium text-gray-900 block mb-2">
                  Name
                </label>
                <div className="relative shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus-within:ring-cyan-600 focus-within:border-cyan-600 w-full">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <CiUser className="text-sky-400" />
                  </div>
                  <input
                    type="text"
                    name="name"
                    id="name"
                    value={userData.name}
                    onChange={handleChange}
                    className="block w-full pl-10 p-2.5 bg-transparent border-0 focus:ring-0"
                    placeholder="Enter Full Name"
                    required
                  />
                </div>
              </div>
              <div className="col-span-6 sm:col-span-3">
                <label
                  htmlFor="dob"
                  className="text-sm font-medium text-gray-900 block mb-2">
                  Date Of Birth
                </label>
                <input
                  type="date"
                  name="dob"
                  id="dob"
                  value={userData.dob}
                  onChange={handleChange}
                  className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5"
                  required
                />
              </div>
              <div className="col-span-6 sm:col-span-3">
                <label
                  htmlFor="gender"
                  className="text-sm font-medium text-gray-900 block mb-2">
                  Gender
                </label>
                <div className="flex items-center mb-2">
                  <input
                    type="radio"
                    name="gender"
                    id="female"
                    value="FEMALE"
                    checked={userData.gender === "FEMALE"}
                    onChange={handleChange}
                    className="text-gray-900 mx-2"
                  />
                  <label
                    htmlFor="female"
                    className="text-sm text-gray-900 mx-2">
                    Female
                  </label>
                </div>
                <div className="flex items-center">
                  <input
                    type="radio"
                    name="gender"
                    id="male"
                    value="MALE"
                    checked={userData.gender === "MALE"}
                    onChange={handleChange}
                    className="text-gray-900 mx-2"
                  />
                  <label htmlFor="male" className="text-sm text-gray-900 mx-2">
                    Male
                  </label>
                </div>
              </div>
              <div className="col-span-6 sm:col-span-3">
                <div>
                  <label
                    htmlFor="interests"
                    className="text-sm font-medium text-gray-900 block mb-2">
                    Select Interest
                  </label>
                  <div
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    className="relative w-full inline-block text-left">
                    <button
                      id="dropdownHoverButton"
                      className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 w-full p-2.5 flex justify-between items-center">
                      Select Interest{" "}
                      <svg
                        className="w-2.5 h-2.5 ml-2.5"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 10 6">
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="m1 1 4 4 4-4"
                        />
                      </svg>
                    </button>
                    {isDropdownVisible && (
                      <div
                        id="dropdownHover"
                        className="absolute z-10 max-h-60 overflow-y-auto shadow-lg bg-white divide-y divide-gray-100 rounded-lg w-60 dark:bg-sky-200">
                        <ul
                          className="py-2 text-sm dark:text-gray-200"
                          aria-labelledby="dropdownHoverButton">
                          {interests.map((value, index) => (
                            <div
                              key={index}
                              className="flex items-center px-4 py-2">
                              <input
                                name="interests"
                                id={`checkbox-${index}`}
                                type="checkbox"
                                onChange={handleChange}
                                value={value}
                                checked={userData.interests.includes(value)}
                                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                              />
                              <label
                                htmlFor={`checkbox-${index}`}
                                className="ml-2 text-sm font-medium text-gray-900">
                                {value}
                              </label>
                            </div>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {userData.length<3 && error(<p className="text-red-600">{error}</p>)}
              <div className="col-span-6 sm:col-span-3">
                <label
                  htmlFor="profession"
                  className="text-sm font-medium text-gray-900 block mb-2">
                  Select Profession
                </label>
                <select
                  id="profession"
                  name="profession"
                  value={userData.profession}
                  onChange={handleChange}
                  className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5">
                  <option value="">Choose Profession</option>
                  {profession.map((value, index) => (
                    <option key={index} value={value}>
                      {value}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-span-6 sm:col-span-3">
                <label
                  htmlFor="bio"
                  className="text-sm font-medium text-gray-900 block mb-2">
                  Bio
                </label>
                <textarea
                  id="bio"
                  name="bio"
                  value={userData.bio}
                  onChange={handleChange}
                  rows={1}
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-3"
                  placeholder="Enter something about you"
                />
              </div>
              <div className="col-span-6 sm:col-span-3">
                <label
                  htmlFor="password"
                  className="text-sm font-medium text-gray-900 block mb-2">
                  Password
                </label>
                <input
                  type="password"
                  name="password"
                  id="password"
                  value={userData.password}
                  onChange={handleChange}
                  className="shadow-sm text-black bg-gray-50 border border-gray-300 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5"
                  required
                />
              </div>

              <div className="col-span-6 sm:col-span-3">
                <label
                  htmlFor="Confirmpass"
                  className="text-sm font-medium text-gray-900 block mb-2">
                  Confirm Password
                </label>
                <input
                  type="password"
                  name="Confirmpass"
                  id="Confirmpass"
                  value={Confirmpass}
                  onChange={(e) => setconfirmPass(e.target.value)}
                  className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5"
                  required
                />
                {userData.password === Confirmpass ? (
                  ""
                ) : Confirmpass !== "" ? (
                  <p className="text-red-600">Passwords do not match</p>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="p-3 flex justify-between border-gray-200 rounded-b">
          <div>
            <Link to="/signUp">
              <button
                type="button"
                className="text-black bg-transparent hover:bg-sky-400 hover:text-white rounded-lg text-lg p-1.5 ml-auto inline-flex items-center"
                data-modal-toggle="product-modal">
                <FaArrowLeft />
              </button>
            </Link>
          </div>
          <button
            className="text-white bg-cyan-600 hover:bg-cyan-700 focus:ring-4 focus:ring-cyan-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
            onClick={handleSave}>
            Save all
          </button>
        </div>
      </div>
    </>
  );
};

export default SignInfo;
