import React, { useState ,useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

const Dropdown = () => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const [profile, setProfile] = useState({});
  useEffect(() => {
    const profileData = localStorage.getItem("profileData");
    if (profileData) {
      setProfile(JSON.parse(profileData));
    }
  }, []);
  const handleLogout = () => {
    localStorage.clear();
    navigate("/Login");
  };

  return (
    <div className="relative">
      <div
        className="h-10 w-10"
        onClick={() => setIsOpen(!isOpen)}
        type="button">
        <img
          className="w-10 h-10 rounded-full"
          src={profile ? profile.profilePicture : ""}
          alt="Rounded avatar"
        />
      </div>
      {isOpen && (
        <div className=" z-10 absolute w-[12rem] right-4 top-full  mt-1 bg-sky-200 bottom-1   ">
          <ul className="p-1 bg-white shadow-2xl">
            <li>
             
              <div className="block font-bold p-1 text-sm text-sky-300 hover:bg-blend-lighten">
                <Link to="/profile">Profile</Link>
              </div>
            </li>
            <li>
              <div
                className="block font-bold p-1 text-sm text-sky-300 hover:bg-blend-lighten"
                onClick={handleLogout}>
                Logout
              </div>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default Dropdown;
