import NavBar from "../src/components/NavBar";
import { ProfileCard } from "./components/ProfileCard";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import EditProfile from "./pages/EditProfile";
import AuthControl from "./pages/AuthControl";
import { Login } from "./pages/userPages/Login";
import SignUp from "./pages/userPages/SignUp/SignUp"; 
import EventListing from "./pages/EventListing";
import EventDetail from "./pages/EventDetail";
import SendOtp from "./pages/userPages/ResetPassword/SendOtp"
import NotFound from "./components/NotFound";
import { useEffect } from "react";
import { useDispatch,useSelector } from "react-redux";
import { fetchProfileData } from "./redux/slice/ProfileSlice";
import HomePage from "./components/HomePage";
import HostProfileCard from "./components/HostProfileCard";
import SignInfo from "./pages/userPages/SignUp/SignInfo";
import EventForm from "./components/CreateEvent";
import Footer from "./components/Footer";



function App() {
  const dispatch =useDispatch();
 const { token }= useSelector((state) => state.auth);
  useEffect(() => {
    dispatch(fetchProfileData(token));
  }, [token]);
  return (
    <BrowserRouter >
      <div className="h-screen !important ">
        <NavBar />
        <Routes>
          <Route path="/" element={<EventListing />} />
          <Route path="/Home" element={<HomePage />} />
          <Route path="/Event/:id" element={<EventDetail />} />
          <Route path="/profile" element={<ProfileCard />} />
          <Route path="/host/:id" element={<HostProfileCard />} />
          <Route path="/editprofile" element={<EditProfile />} />
          <Route path="/" element={<AuthControl />}>
            <Route path="Login" element={<Login />} />
            <Route path="signUp" element={<SignUp />} />
            <Route path="/signUp/detail" element={<SignInfo />} />
            <Route path="/resetPassword" element={<SendOtp />} />
          </Route>
          <Route path="/createEvent" element={<EventForm />} />
          <Route path='*' element={<NotFound/>} />
        </Routes> 
        <Footer/>
      </div>
     
    </BrowserRouter>
  );
}

export default App;
