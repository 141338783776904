import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

// Define the async thunk
export const resetPassword = createAsyncThunk(
    'auth/resetPassword',
    async ({ id, pass }, thunkAPI) => {
        try {
            const response = await axios.post('https://api.sociverse.in/auth/resetPassword', {
                "email": id,
                "password": pass
            });
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

// Create the slice
const resetPassSlice = createSlice({
    name: 'resetPass',
    initialState: {
        status: 'idle',
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(resetPassword.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(resetPassword.fulfilled, (state, action) => {
                state.status = 'succeeded';
            })
            .addCase(resetPassword.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            });
    },
});

export default resetPassSlice.reducer;
